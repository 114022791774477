import React from 'react';
import { graphql, Link } from 'gatsby';
import { getKeyedResources } from '../../../utils/resource-helper';
import Layout from '../../../components/Layout';
import Section from '../../../components/Section';
import { Container, Row, Column } from '../../../components/Grid';
import Icon from '../../../components/Icon';
import Image from '../../../components/Image';
import Hero from '../../../components/Hero';
import SubfooterNav from '../../../components/SubfooterNav';
import Resource from '../../../components/Resource';
import bgImage from '../../../images/bg_ag_environmental_texture.jpg';
import '../../../styles/pages/_unit-sections.scss';

const AgEnvironmentalUnit8 = ({ data }) => {
  const heroBreadcrumbs = {
    unitLabel: 'UNIT 8',
    linkLabel: 'AG/ENVIRONMENTAL COURSE',
    to: '/classroom-instruction/ag-environmental/',
  };

  const {
    agCommunityScienceIntro,
    agCommunityApplicationOfDnaIdentification,
    agProposalDevelopmentLessonOne,
    agProposalDevelopmentLessonTwo,
    agProposalDevelopmentLessonThree,
    agDnaTechnologyLessonOne,
    agDnaTechnologyLessonTwo,
    agDnaTechnologyLessonThree,
    agDnaTechnologyLessonFour,
    agDecisionTreeCreation,
    agDnaCollectionKitDesign,
    agFinalArtifact,
  } = getKeyedResources(data);

  return (
    <Layout title="Ag-Environmental Unit 8">
      {/* -----------------UNIT BANNER----------------------- */}
      <Hero
        className={'hero--small course__hero'}
        modal
        imageSource="header-plant-unit8@2x.jpg"
        guidSrc="1e7e5c97-2a63-4404-8564-32650b3daf63"
        posterSrc={'unit-8-agplant-intro-video-still.gif'}
        breadcrumbs={heroBreadcrumbs}
        color={'green'}
        playtext={'Video Intro'}
      >
        <h1 className=" mb-0 pb-0">Community Science</h1>
        <p className="pb-2">
          How can we use DNA identification to solve problems in our lives, in
          our community, and on our planet?
        </p>
      </Hero>
      {/* ----------------- AT A GLANCE RIBBON ----------------- */}
      <Section className="unit-sections__at-a-glance-section pt-0 pb-0">
        <Container
          fullWidth
          className="unit-sections__at-a-glance-container pt-2 pb-1"
        >
          <div className="course__unit-indicator text-bold">
            <div>AG/ENVIRONMENTAL | UNIT 8</div>
            <Link
              to="/classroom-instruction/ag-environmental/"
              className="course__unit-indicator-link"
            >
              Overview <Icon marginLeft name="arrowright" />
            </Link>
          </div>
          <h4 className="mt-0.5 text-bold">At a Glance</h4>
          <Row>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-storyline.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                STORYLINE
              </h3>
              <ul className="course__list course__list-style-none">
                <li className="mr-3">
                  Learn about three DNA identification techniques—Sanger
                  sequencing, digital PCR, and NextGen sequencing—and how they
                  could be used to address local community issues.
                </li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-pacing.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                PACING
              </h3>
              <ul className="course__list">
                <li>9 week unit</li>
                <li>45-minute classes</li>
                <li>Built in flex days</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-framing.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                FRAMING
              </h3>
              <ul className="course__list">
                <li>Standards Aligned</li>
                <li>Driving Question</li>
                <li>Engineering Design Process</li>
                <li>Project Based</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-lesson-plans-labs.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                LESSON PLANS AND LABS
              </h3>
              <ul className="course__list">
                <li>4 Core Lessons</li>
                <li>3 Proposal Development Lessons</li>
                <li>4 DNA Technology Lessons</li>
                <li>1 Final Project Artifact</li>
              </ul>
            </Column>
          </Row>
        </Container>
      </Section>
      {/* ----------------- COURSE CARDS INTRO ----------------- */}
      <Section
        style={{ backgroundImage: `url(${bgImage})` }}
        className="pt-0 pb-3"
      >
        <Container fullWidth className="pt-4 pb-1">
          <Row className="mb-2">
            <Column size={7}>
              <h3 className="text-bold">
                Lesson Plans, Labs and Student Capture Sheets
              </h3>
              <p>
                This unit includes lesson plans, labs, and student capture
                sheets that empower educators to engage all students. These
                lesson bundles can be downloaded, for use in the classroom.
                Lesson 1 should be taught first. The proposal development (PD)
                lessons and DNA technology (Tech) lessons can be interspersed
                with each other, but should still be taught in order. Lessons 9,
                10, and 11 require knowledge of all previous lessons. See{' '}
                <a
                  href="/pdfs/ag-environmental/Gen-AG-U8-Pacing.pdf"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  Pacing Guide
                </a>{' '}
                for an example outline.
              </p>
            </Column>
            {/* Full unit zip file download */}
            <Column offset={1} size={4}>
              <div className="unit_download">
                <a href="/units/Gen-AG-U8.zip" className="button">
                  <Icon marginRight name="download" />
                  Complete Unit
                </a>
                <span className="unit_download">ZIP, 189.4 MB</span>
              </div>
            </Column>
          </Row>
        </Container>
        {/* ----------------- COURSE CARDS ----------------- */}
        <Container fullWidth>
          <Row>
            {/* INTRO */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agCommunityScienceIntro.tophat}
                tophatClass={`${agCommunityScienceIntro.page}__resource-tophat`}
                ribbon={agCommunityScienceIntro.ribbon}
                ribbonClass={`${agCommunityScienceIntro.page}__resource-ribbon`}
                img=""
                duration={agCommunityScienceIntro.duration}
                subject={agCommunityScienceIntro.subject}
                subjectClass={`${agCommunityScienceIntro.page}__resource-subject`}
                title={agCommunityScienceIntro.title}
                description={agCommunityScienceIntro.description}
                actions={agCommunityScienceIntro.actions}
              />
            </Column>
            {/* LESSON 1 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agCommunityApplicationOfDnaIdentification.tophat}
                tophatClass={`${agCommunityApplicationOfDnaIdentification.page}__resource-tophat`}
                ribbon={agCommunityApplicationOfDnaIdentification.ribbon}
                ribbonClass={`${agCommunityApplicationOfDnaIdentification.page}__resource-ribbon`}
                img=""
                duration={agCommunityApplicationOfDnaIdentification.duration}
                subject={agCommunityApplicationOfDnaIdentification.subject}
                subjectClass={`${agCommunityApplicationOfDnaIdentification.page}__resource-subject`}
                title={agCommunityApplicationOfDnaIdentification.title}
                description={
                  agCommunityApplicationOfDnaIdentification.description
                }
                actions={agCommunityApplicationOfDnaIdentification.actions}
              />
            </Column>
            {/* PROP DEVELOPMENT - LESSON 1 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agProposalDevelopmentLessonOne.tophat}
                tophatClass={`${agProposalDevelopmentLessonOne.page}__resource-tophat`}
                ribbon={agProposalDevelopmentLessonOne.ribbon}
                ribbonClass={`${agProposalDevelopmentLessonOne.page}__resource-ribbon`}
                img=""
                duration={agProposalDevelopmentLessonOne.duration}
                subject={agProposalDevelopmentLessonOne.subject}
                subjectClass={`${agProposalDevelopmentLessonOne.page}__resource-subject`}
                title={agProposalDevelopmentLessonOne.title}
                description={agProposalDevelopmentLessonOne.description}
                actions={agProposalDevelopmentLessonOne.actions}
              />
            </Column>
            {/* PROP DEVELOPMENT - LESSON 2 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agProposalDevelopmentLessonTwo.tophat}
                tophatClass={`${agProposalDevelopmentLessonTwo.page}__resource-tophat`}
                ribbon={agProposalDevelopmentLessonTwo.ribbon}
                ribbonClass={`${agProposalDevelopmentLessonTwo.page}__resource-ribbon`}
                img=""
                duration={agProposalDevelopmentLessonTwo.duration}
                subject={agProposalDevelopmentLessonTwo.subject}
                subjectClass={`${agProposalDevelopmentLessonTwo.page}__resource-subject`}
                title={agProposalDevelopmentLessonTwo.title}
                description={agProposalDevelopmentLessonTwo.description}
                actions={agProposalDevelopmentLessonTwo.actions}
              />
            </Column>
            {/* PROP DEVELOPMENT - LESSON 3 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agProposalDevelopmentLessonThree.tophat}
                tophatClass={`${agProposalDevelopmentLessonThree.page}__resource-tophat`}
                ribbon={agProposalDevelopmentLessonThree.ribbon}
                ribbonClass={`${agProposalDevelopmentLessonThree.page}__resource-ribbon`}
                img=""
                duration={agProposalDevelopmentLessonThree.duration}
                lab={agProposalDevelopmentLessonThree.lab}
                subject={agProposalDevelopmentLessonThree.subject}
                subjectClass={`${agProposalDevelopmentLessonThree.page}__resource-subject`}
                title={agProposalDevelopmentLessonThree.title}
                description={agProposalDevelopmentLessonThree.description}
                actions={agProposalDevelopmentLessonThree.actions}
              />
            </Column>
            {/* DNA TECH - LESSON 1 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agDnaTechnologyLessonOne.tophat}
                tophatClass={`${agDnaTechnologyLessonOne.page}__resource-tophat`}
                ribbon={agDnaTechnologyLessonOne.ribbon}
                ribbonClass={`${agDnaTechnologyLessonOne.page}__resource-ribbon`}
                img=""
                duration={agDnaTechnologyLessonOne.duration}
                lab={agDnaTechnologyLessonOne.lab}
                subject={agDnaTechnologyLessonOne.subject}
                subjectClass={`${agDnaTechnologyLessonOne.page}__resource-subject`}
                title={agDnaTechnologyLessonOne.title}
                description={agDnaTechnologyLessonOne.description}
                actions={agDnaTechnologyLessonOne.actions}
              />
            </Column>

            {/* DNA TECH - LESSON 2 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agDnaTechnologyLessonTwo.tophat}
                tophatClass={`${agDnaTechnologyLessonTwo.page}__resource-tophat`}
                ribbon={agDnaTechnologyLessonTwo.ribbon}
                ribbonClass={`${agDnaTechnologyLessonTwo.page}__resource-ribbon`}
                img=""
                duration={agDnaTechnologyLessonTwo.duration}
                subject={agDnaTechnologyLessonTwo.subject}
                subjectClass={`${agDnaTechnologyLessonTwo.page}__resource-subject`}
                title={agDnaTechnologyLessonTwo.title}
                description={agDnaTechnologyLessonTwo.description}
                actions={agDnaTechnologyLessonTwo.actions}
              />
            </Column>
            {/* DNA TECH - LESSON 3 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agDnaTechnologyLessonThree.tophat}
                tophatClass={`${agDnaTechnologyLessonThree.page}__resource-tophat`}
                ribbon={agDnaTechnologyLessonThree.ribbon}
                ribbonClass={`${agDnaTechnologyLessonThree.page}__resource-ribbon`}
                img=""
                duration={agDnaTechnologyLessonThree.duration}
                subject={agDnaTechnologyLessonThree.subject}
                subjectClass={`${agDnaTechnologyLessonThree.page}__resource-subject`}
                title={agDnaTechnologyLessonThree.title}
                description={agDnaTechnologyLessonThree.description}
                actions={agDnaTechnologyLessonThree.actions}
              />
            </Column>
            {/* DNA TECH - LESSON 4 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agDnaTechnologyLessonFour.tophat}
                tophatClass={`${agDnaTechnologyLessonFour.page}__resource-tophat`}
                ribbon={agDnaTechnologyLessonFour.ribbon}
                ribbonClass={`${agDnaTechnologyLessonFour.page}__resource-ribbon`}
                img=""
                duration={agDnaTechnologyLessonFour.duration}
                subject={agDnaTechnologyLessonFour.subject}
                subjectClass={`${agDnaTechnologyLessonFour.page}__resource-subject`}
                title={agDnaTechnologyLessonFour.title}
                description={agDnaTechnologyLessonFour.description}
                actions={agDnaTechnologyLessonFour.actions}
              />
            </Column>
            {/* LESSON 9 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agDecisionTreeCreation.tophat}
                tophatClass={`${agDecisionTreeCreation.page}__resource-tophat`}
                ribbon={agDecisionTreeCreation.ribbon}
                ribbonClass={`${agDecisionTreeCreation.page}__resource-ribbon`}
                img=""
                duration={agDecisionTreeCreation.duration}
                subject={agDecisionTreeCreation.subject}
                subjectClass={`${agDecisionTreeCreation.page}__resource-subject`}
                title={agDecisionTreeCreation.title}
                description={agDecisionTreeCreation.description}
                actions={agDecisionTreeCreation.actions}
              />
            </Column>
            {/* LESSON 10 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agDnaCollectionKitDesign.tophat}
                tophatClass={`${agDnaCollectionKitDesign.page}__resource-tophat`}
                ribbon={agDnaCollectionKitDesign.ribbon}
                ribbonClass={`${agDnaCollectionKitDesign.page}__resource-ribbon`}
                img=""
                duration={agDnaCollectionKitDesign.duration}
                subject={agDnaCollectionKitDesign.subject}
                subjectClass={`${agDnaCollectionKitDesign.page}__resource-subject`}
                title={agDnaCollectionKitDesign.title}
                description={agDnaCollectionKitDesign.description}
                actions={agDnaCollectionKitDesign.actions}
              />
            </Column>
            {/* LESSON 11 */}
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={agFinalArtifact.tophat}
                tophatClass={`${agFinalArtifact.page}__resource-tophat`}
                ribbon={agFinalArtifact.ribbon}
                ribbonClass={`${agFinalArtifact.page}__resource-ribbon`}
                img=""
                duration={agFinalArtifact.duration}
                subject={agFinalArtifact.subject}
                subjectClass={`${agFinalArtifact.page}__resource-subject`}
                title={agFinalArtifact.title}
                description={agFinalArtifact.description}
                actions={agFinalArtifact.actions}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      {/*---------------------BREADCRUMB NAV-----------------*/}
      <Section className="pb-0 pt-0">
        <SubfooterNav
          current={{
            name: 'Unit 8',
            link: '/classroom-instruction/ag-environmental/unit-8',
          }}
          next={{
            name: 'Overview',
            link: '/classroom-instruction/ag-environmental',
          }}
          previous={{
            name: 'Unit 7',
            link: '/classroom-instruction/ag-environmental/unit-7',
          }}
        />
      </Section>
    </Layout>
  );
};

export default AgEnvironmentalUnit8;

export const query = graphql`
  query AgenviroUnit8Resources {
    allResourcesJson(
      filter: { page: { eq: "ag-environmental" }, unit: { eq: 8 } }
    ) {
      edges {
        node {
          slug
          unit
          page
          ribbon
          tophat
          img
          subject
          title
          duration
          lab
          description
          actions {
            label
            type
            format
            size
            path
            title
            clickLabel
            clickType
            actions {
              label
              type
              format
              size
              path
              title
              clickLabel
              clickType
              guidSrc
              posterSrc
            }
          }
        }
      }
    }
  }
`;
